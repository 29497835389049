<template>
  <div class="examples">
    <div class="actions">
      <PassportButton @click="showModal = true" label="Create new trivia or quiz" />
    </div>
    <PassportModal disableClosingOnMask v-model="showModal">
      <div class="section">
        <span class="modal-heading">Select Type:</span>
        <div class="choice-box" @click="$router.push('/create-trivia')">
          <span class="heading">Trivia </span>
          <span class="choice-text">
            Uses grading and defined pass percentage to determine user score, or uses participation to award points.
          </span>
        </div>
        <div class="choice-box" @click="$router.push('/create-personality-quiz')">
          <span class="heading">Personality Quiz</span>
          <span class="choice-text"> Uses point totals to give the user a specific result. </span>
        </div>
        <div class="cancel-modal">
          <PassportButton variant="text" @click="showModal = false" label="Cancel" />
        </div>
      </div>
    </PassportModal>
    <span class="page-heading"> Trivia & Quizzes </span>
    <hr class="line-break" />
    <QuizTable heading="trivia & quizzes" />
  </div>
</template>

<script>
import PassportButton from '@/components/PassportButton.vue';
import PassportModal from '@/components/PassportModal.vue';
import QuizTable from '@/components/QuizTable.vue';

export default {
  name: 'default',
  components: {
    PassportButton,
    PassportModal,
    QuizTable,
  },
  data() {
    return {
      showModal: false,
      exRadioList: null,
      exChecked: false,
      exSwitch: false,
      selectedRows: [],
      selectedOption: null,
      options: [
        {
          label: 'Text only',
          type: 'text',
        },
        {
          label: 'Image',
          type: 'image',
        },
        {
          label: 'Video',
          type: 'video',
        },
        {
          label: 'Link',
          type: 'link',
        },
        {
          label: 'Personality Quiz',
          type: 'quiz',
        },
        {
          label: 'Trivia',
          type: 'trivia',
        },
      ],
    };
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.examples {
  .actions {
    display: flex;
    justify-content: flex-end;
  }

  .line-break {
    width: 1601px;
    margin-top: 3em;
    margin-bottom: 3em;
    opacity: 0.1;
  }
  .page-heading {
    display: flex;
    font-size: 28px;
    letter-spacing: -0.7px;
  }
  .example {
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid white;
    border-radius: 10px;

    ::v-deep {
      .header {
        padding: 10px;
      }
      .collapse-wrapper > .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
.buttons {
  display: flex;
  justify-content: flex-end;
}
::v-deep .passport-button.outline.primary {
  font-size: 14px;
  text-transform: uppercase;
}
::v-deep .passport-button.default.primary {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 14px;
  text-transform: uppercase;
}
::v-deep .g-input .input-element:not([type='submit'], [type='range']) {
  width: 315px;
  font-size: 18px;
}

.box {
  display: flex;
  justify-content: space-between;
  width: 15.625em;
  padding: 10px;
  margin: auto;
  margin-bottom: 10px;
  background: #ffffff26 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
}
.modal-heading {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
  font-size: 34px;
}
.choice-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 401px;
  padding: 30px;
  margin: auto;
  margin-bottom: 1em;
  background: #ffffff26 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
}
.heading {
  margin-bottom: 1em;
  font-size: 34px;
}
.choice-text {
  font-size: 16px;
}
.cancel-modal {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}
.section {
  width: 600px;
  padding: 60px;
  background-image: url(../assets/images/bg_modal.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.choice-box:hover {
  opacity: 30%;
}
</style>
