<template>
  <section class="filter" :class="{ 'filter--open': isPopoverOpen }">
    <BaseButton class="filter-button" :class="{ open: isPopoverOpen }" @click="toggleFilterModal">
      <span class="text--body">{{ label || 'Filter' }}</span>
      <span class="icon-badge" :class="{ hidden: !badgeCount }">{{ badgeCount }}</span>
    </BaseButton>
    <transition :name="transitionName">
      <div v-if="isPopoverOpen" class="filter-modal" v-click-outside="triggerApplyAndClose">
        <header class="modal-header">
          <BaseButton class="close-button" variant="text" @click="toggleFilterModal">
            <CloseIcon class="close-icon" />
          </BaseButton>
          <slot name="header">
            <h4 v-if="label">{{ label }}</h4>
          </slot>
        </header>
        <div class="modal-body">
          <slot name="body" class="body"></slot>
        </div>
        <footer class="modal-footer">
          <slot name="footer">
            <div class="button-container">
              <BaseButton class="reset" variant="outline secondary" @click="clearAllFilters">Reset</BaseButton>
              <BaseButton variant="secondary" @click="triggerApplyAndClose">Apply</BaseButton>
            </div>
          </slot>
        </footer>
      </div>
    </transition>
  </section>
</template>

<script>
import CloseIcon from '@/assets/icons/x_icon.svg';
import BaseButton from '@/components/PassportButton.vue';

export default {
  name: 'base-popover',
  components: {
    CloseIcon,
    BaseButton,
  },
  data() {
    return {
      isPopoverOpen: false,
    };
  },
  props: {
    label: String,
    badgeCount: Number,
  },
  computed: {
    transitionName() {
      return this.$mq === 'sm' ? 'slide' : 'fade';
    },
  },
  methods: {
    triggerApplyAndClose() {
      this.toggleFilterModal();
      this.$emit('apply');
    },
    toggleFilterModal() {
      this.isPopoverOpen = !this.isPopoverOpen;
    },
    clearAllFilters() {
      this.filters = this.value;
      this.$emit('reset');
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  position: relative;
  color: black;

  &--open {
    @include bp-lg-laptop {
      z-index: 1;
      filter: drop-shadow(0 2px 6px #232f3e26);
    }
  }
  &-button {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 9px 15px;
    color: $dark-neutral;

    &::after {
      width: 18px;
      height: 9.5px;
      content: '';
      background: url('~@/assets/icons/carat.svg?external') center center no-repeat;
      background-repeat: no-repeat;
      background-size: 18px 9.5px;
      transition: transform 100ms;
      transform-origin: center;
    }
    &.open {
      background-color: $lightest-neutral;
      &::after {
        transform: rotate(180deg);
      }
    }
    span {
      vertical-align: middle;
    }
    .icon-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin: 0 5px;
      font-size: 14px;
      line-height: 1;
      color: $lightest-neutral;
      background: $primary-color;
      border-radius: 9999px;
      &.hidden {
        visibility: hidden;
      }

      @include bp-lg-laptop {
        margin-left: 10px;
      }
    }
  }
  &-modal {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: left;
    background: white;
    transform-origin: 100% 0;
    .button-container {
      display: flex;
      flex-basis: 100%;
      justify-content: flex-end;
      margin-top: 10px;
      .secondary {
        display: flex;
        align-items: center;
        margin-right: 12px;
        font-size: 14px;
        text-transform: uppercase;
      }
    }
    @include bp-lg-laptop {
      position: absolute;
      top: 100%;
      right: initial;
      left: 0;
      z-index: 999;
      width: fit-content;
      height: fit-content;
      padding: 35px;
      font-size: 14px;
      border-radius: 0 3px 3px 3px;
    }
    .modal {
      &-header {
        position: relative;
        display: flex;
        align-items: center;
        text-align: center;
        background-color: $dark-neutral;
        @include bp-lg-laptop {
          display: none;
        }
        h4 {
          padding: 18px 0;
          margin: 0;
          line-height: 1.2;
          color: white;
        }
        .close-button {
          svg {
            display: block;
            width: 18px;
            height: 18px;
          }
        }
      }
      &-body {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 0 35px;
        margin-bottom: 20px;
        color: black;
        @include bp-lg-laptop {
          flex-wrap: nowrap;
          padding: 0;
        }
      }
      &-footer {
        width: 100%;
        padding: 0 35px;
        padding-bottom: 20px;
        @include bp-lg-laptop {
          padding: 0;
        }
      }
    }
  }
}
</style>
